import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'


const Hero = () => {
    const transition = {type:'spring', duration:3}
    const mobile = window.innerWidth<= 768? true:false;
  return (
    <div className="hero" id='hero'>
        <div className="blur blur-hero"></div>
        <div className="left-h">
            <Header/>
            {/* The best ad  */}
            <div className="the-best">
                <motion.div  initial = {{left : mobile? '178px' :'238px'}} whileInView={{left :'8px'}} transition={{...transition, type : 'tween'}}>
               

                </motion.div>
                <span>The best Fitness club in the town</span>
            </div>
            {/* Hero heading */}
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>SHAPE </span>
                    <span>YOUR</span>
                </div>
                <div>
                    <span>IDEAL BODY</span>
                    
                </div>
               <div className='para'>
                <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
               </div>
            </div>
            {/* figures */}
            <div className="figures">
                <div>
                    <span><NumberCounter end = {140} start = {100} delay = '4' preFix = "+"/></span>
                    <span>expert coaches</span>
                </div>
                <div>
                    <span><NumberCounter end = {978} start = {100} delay = '4' preFix = "+"/></span>
                    <span>members joined</span>
                </div>
                <div>
                    <span><NumberCounter end = {50} start = {10} delay = '2' preFix = "+"/></span>
                    <span>fitness programs</span>
                </div>
            </div>
            {/* Hero button  */}
            <div className="hero-buttons">
                <button className="btn">Get Started</button>
                <button className="btn">learn More</button>
            </div>
        </div>
        <div className="right-h">
            
            <button className='btn'>Join Now</button>

            <motion.div className='heart-rate' transition={transition} whileInView={{right:'4rem'}}initial = {{right:'-1rem'}}>
                <img src={Heart} alt="" />
                <span>Heart Rate</span> <span> 116 bpm</span>
            </motion.div>

            {/* Hero image  */}
            <img src={hero_image} alt=""  className='hero-image'/>
            <motion.img src={hero_image_back} alt=""  className='hero-image-back' initial= {{right:'11rem'}} whileInView={{right:'20rem'}} transition={transition}/>
            {/* Calories  */}
            <motion.div  initial = {{right:'37rem'}} whileInView = {{right:'28rem'}} transition = {transition} className="calories">
                <img src={Calories} alt="" />
                <div>
                <span>Calories Burned</span>
                <span>220 kcal</span>
                </div>
                
            </motion.div>
        </div>
    </div>
  )
}

export default Hero